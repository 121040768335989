import axiosInstance from "../axios/axiosInstance";

const USERNAME_REGEX = /^[a-zA-Z0-9\s._-]{2,32}$/;

export async function getUser() {
  try {
    const response = await axiosInstance.get(`/user?include=affiliate`);
    const user = response.data;
    // console.log(user)
    if (user.newToken) {
      localStorage.setItem("authToken", user.newToken);
    }
    return user;
  } catch (error) {
    //console.error("Error getting user:", error);
    return null;
  }
}

export async function getUserById(id: any) {
  try {
    const response = await axiosInstance.get(`/user/${id}`);
    const user = response.data;
    return user;
  } catch (error) {
    console.error(`Error getting user by id ${id}:`, error);
    return null;
  }
}

export async function getUserDailyCredits() {
  try {
    const response = await axiosInstance.get(`/user/collect/reward`);
    const dailyCredits = response.data;
    return dailyCredits;
  } catch (error) {
    console.error("Error getting user daily credits:", error);
    return null;
  }
}

export async function getUsernameById(userId: any) {
  try {
    const response = await axiosInstance.get(`/user/name/${userId}`);
    const usernameData = response.data; // Expecting data in the format { username: "name" }
    return usernameData; // Returning only the username string
  } catch (error) {
    console.error(`Error getting username by id ${userId}:`, error);
    return null;
  }
}

export async function setUserPrivacy(userId: string, isPrivate: boolean) {
  try {
    const payload = {
      preferences: {
        privacy: {
          privateProfile: isPrivate,
        },
      },
    };
    const response = await axiosInstance.post(
      `/user/${userId}/preferences`,
      payload,
    );
    // return isPrivate;
    return response.data; // Assuming the API might return some data on success.
  } catch (error) {
    console.error(`Error setting user privacy for user id ${userId}:`, error);
    return null;
  }
}

export async function checkUsernameAvailability(username: string) {
  try {
    const response = await axiosInstance.get(
      `/user/username-available/${username}`,
    );
    const isAvailable = response.data; // Assuming the response data indicates availability
    return isAvailable;
  } catch (error) {
    console.error(
      `Error checking username availability for ${username}:`,
      error,
    );
    return null;
  }
}

export async function updateUsername(userId: string, newUsername: string) {
  try {
    const payload = {
      username: newUsername,
    };
    const response = await axiosInstance.post(
      `/user/${userId}/username`,
      payload,
    );
    return { response: response.data }; // Assuming the API might return some data on success.
  } catch (error) {
    console.error(`Error updating username for user id ${userId}:`, error);
    return { error: error };
  }
}

// export async function updateUsername(userId:string, newUsername:string) {
//   try {
//     const payload = {
//       username: newUsername
//     };
//     const response = await axiosInstance.post(`/user/${userId}/username`, payload);
//     return response.data; // Assuming the API might return some data on success.
//   } catch (error) {
//     console.error(`Error updating username for user id ${userId}:`, error);
//     return null;
//   }
// }

export const isValidUsername = (username: string): { valid: boolean; reason?: string } => {
  // Check if username exists
  if (!username) {
    return {
      valid: false,
      reason: 'Username is required'
    };
  }

  // Check length
  if (username.length < 2 || username.length > 32) {
    return {
      valid: false,
      reason: 'Username must be between 2 and 32 characters long'
    };
  }

  // Check for leading/trailing spaces
  if (username !== username.trim()) {
    return {
      valid: false,
      reason: 'Username cannot start or end with spaces'
    };
  }

  // Check basic character set
  if (!USERNAME_REGEX.test(username)) {
    return {
      valid: false,
      reason:
        'Username can only contain letters, numbers, spaces, periods, underscores, and hyphens'
    };
  }

  // Check for consecutive spaces
  if (/\s\s/.test(username)) {
    return {
      valid: false,
      reason: 'Username cannot contain consecutive spaces'
    };
  }

  // Check for consecutive periods
  if (/\.\./.test(username)) {
    return {
      valid: false,
      reason: 'Username cannot contain consecutive periods'
    };
  }

  return { valid: true };
};